import Model from '@/dc-it/models/Model'
import Field from '@/dc-it/types/Field'
import Multimedia from '@/dc-it/models/Multimedia'
import MultipleMultimedia from '@/dc-it/types/MultipleMultimedia'

export default class Showroom extends Model {
    endpoint = 'showrooms'

    related = ['multimedia']

    fields = [
      new Field('id', 'Código').disabled(),
      new MultipleMultimedia('multimedia', 'Imagen', Multimedia).hide().setLimit(1),
      new Field('title', 'Título'),
      new Field('url', 'URL'),
    ]

    clone = () => Showroom
}
